.header_common {
  user-select: none;
}
.header_common .header_common_title_container {
  display: flex;
  border-bottom: 1px solid rgba(0, 118, 255, 0.8);
  height: 0.6rem;
  align-items: center;
  justify-content: space-between;
}
.header_common .header_common_title_container .header_common_title_container_left {
  display: flex;
}
.header_common .header_common_title_container .header_common_title {
  white-space: nowrap;
  position: relative;
  background: rgba(30, 82, 179, 0.4);
  border-top: 1px solid #00d0ff;
  padding-left: 0.32rem;
  padding-right: 0.3rem;
  font-size: 0.3rem;
  font-weight: bold;
  line-height: 0.6rem;
  color: #e9f0ff;
}
.header_common .header_common_title_container .header_common_title .header_right_icon {
  height: 100%;
  position: absolute;
  right: -0.58rem;
  top: -1px;
}
.header_common .header_common_title_container .header_common_title .header_left_icon {
  height: 100%;
  position: absolute;
  left: -0.58rem;
  top: -1px;
  transform: rotateY(180deg);
}
.header_common .header_common_title_container .header_right_title {
  white-space: nowrap;
  position: relative;
  background: rgba(30, 82, 179, 0.4);
  border-top: 1px solid #00d0ff;
  padding-left: 0.32rem;
  padding-right: 0.3rem;
  color: #e9f0ff;
  display: flex;
  height: 0.6rem;
  justify-content: center;
  align-items: center;
}
.header_common .header_common_title_container .header_right_title .header_left_icon {
  height: 100%;
  position: absolute;
  left: -0.58rem;
  top: -1px;
  transform: rotateY(180deg);
}
.header_common .header_common_title_container .header_right_title .right_groups {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.header_common .header_common_title_container .header_right_title .right_groups .right_week {
  font-size: 0.18rem;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #b8ceff;
}
.header_common .header_common_title_container .header_right_title .right_groups .right_date {
  font-size: 0.2rem;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #b8ceff;
}
.header_common .header_common_title_container .header_right_title .right_hours {
  margin-left: 0.2rem;
  font-size: 0.48rem;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #e9f0ff;
  width: 2rem;
}
.header_common .header_common_title_container .menu_container {
  display: flex;
  align-items: center;
  padding-left: 0.9rem;
}
.header_common .header_common_title_container .menu_container .col_line {
  margin-right: 12px;
  height: 40px;
}
.header_common .header_common_title_container .menu_container .menu_list {
  width: 1.4rem;
  height: 40px;
  background: rgba(30, 82, 179, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  transition: all 0.3s ease;
}
.header_common .header_common_title_container .menu_container .menu_list:hover {
  background: rgba(30, 82, 179, 0.4);
}
.header_common .header_common_title_container .menu_container .menu_list:hover .a_ {
  color: rgba(255, 255, 255, 0.7);
}
.header_common .header_common_title_container .menu_container .menu_list .a_ {
  height: 0.48rem;
  font-size: 0.24rem;
  color: rgba(184, 206, 255, 0.5);
  line-height: 0.48rem;
  text-align: center;
  flex: 1;
  cursor: pointer;
}
.header_common .header_common_title_container .menu_container .menu_list .a_.selected {
  font-weight: bold;
  color: #ffffff;
}
.header_common .header_common_title_container .menu_container .menu_list.selected {
  background: rgba(30, 82, 179, 0.7);
}
.header_common .header_common_title_container .menu_container .menu_list.selected .a_ {
  color: #ffffff;
}
.header_common .header_common_title_container .menu_container .menu_list:active {
  background: rgba(30, 82, 179, 0.7);
}
.header_common .header_common_title_container .login_out_container {
  padding-left: 0;
  justify-content: flex-end;
}
