body {
    font-family: arial, helvetica, sans-serif;
    font-size: 12px;
    font-size-adjust: none;
    font-stretch: normal;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
}

body,
ul,
ol,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
p,
form,
fieldset,
legend,
input,
textarea,
select,
button,
th,
td {
    margin: 0;
    padding: 0;
    line-height: 1;
}



h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
}

table {
    font-size: inherit;
}

input,
select {
    font-family: arial, helvetica, clean, sans-serif;
    font-size: 100%;
    font-size-adjust: none;
    font-stretch: normal;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: normal;
}

button {
    overflow: visible;
}

th,
em,
strong,
b,
address,
cite {
    font-style: normal;
    /* font-weight: normal; */
}

li {
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
}

img,
fieldset {
    border: 0 none;
}

ins {
    text-decoration: none;
}
.anchorBL{
    display: none;
}