body,
html {
  width: 100%;
  min-width: 1300px;
}
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #121629;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#root .page_container {
  padding: 0.32rem;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url('../images/bg_img.png');
}
#root .center_flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.col_line {
  position: relative;
  background: rgba(0, 118, 255, 0.8);
  width: 1px;
  height: 100%;
}
.col_line::after {
  content: '';
  position: absolute;
  top: 0;
  width: 1px;
  height: 4px;
  background: #00d0ff;
  box-shadow: 0 2px 3px 0 #00d0ff;
}
.col_line::before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 1px;
  height: 4px;
  background: #00d0ff;
  box-shadow: 0 2px 3px 0 #00d0ff;
}
.row_line {
  position: relative;
  background: rgba(0, 118, 255, 0.8);
  height: 1px;
  width: 100%;
}
.row_line::after {
  content: '';
  position: absolute;
  left: 0;
  height: 1px;
  width: 4px;
  background: #00d0ff;
  box-shadow: 0 2px 3px 0 #00d0ff;
}
.row_line::before {
  content: '';
  position: absolute;
  right: 0;
  height: 1px;
  width: 4px;
  background: #00d0ff;
  box-shadow: 0 2px 3px 0 #00d0ff;
}
.chart_container {
  flex: 1;
  background: rgba(17, 32, 85, 0.8);
}
.fourCornersWrap {
  display: flex;
  justify-content: space-between;
  margin-top: 0.1rem;
}
.fourCornersWrap .supplies_analzy_statisic {
  display: flex;
  justify-content: space-between;
  padding: 0.15rem 0.07rem;
  background: rgba(17, 32, 85, 0.8);
  box-shadow: 0px 4px 8px 0px rgba(2, 29, 46, 0.5);
  border: 1px solid rgba(0, 118, 255, 0.4);
}
.fourCornersWrap .supplies_analzy_statisic_list {
  flex: 1;
  padding: 0.16rem;
  position: relative;
  padding: 0.06rem 0.03rem;
  display: flex;
  height: 0.4rem;
  margin: 0 0.15rem;
  border-left: 2px solid rgba(30, 82, 179, 0.6);
  border-right: 2px solid rgba(30, 82, 179, 0.6);
  box-sizing: border-box;
}
.fourCornersWrap .supplies_analzy_statisic_list .row_line {
  position: absolute;
  top: 0;
  background-color: transparent;
  left: -2px;
  height: 2px;
  right: -2px;
  width: auto;
}
.fourCornersWrap .supplies_analzy_statisic_list .row_line.bot {
  top: 0.4rem;
}
.fourCornersWrap .supplies_analzy_statisic_list .analzy_card {
  flex: 1;
  background: rgba(30, 82, 179, 0.6);
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.1rem;
}
.fourCornersWrap .supplies_analzy_statisic_list .analzy_card .title_analzy {
  font-size: 0.16rem;
  font-family: MicrosoftYaHei;
  color: #b8ceff;
}
.fourCornersWrap .supplies_analzy_statisic_list .analzy_card .count_analzy {
  font-size: 0.16rem;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #00d0ff;
}
.fourCornersWrap .supplies_analzy_statisic_list .analzy_card .count_analzy .col_line {
  right: auto;
  position: relative;
  margin: 0 0.1rem;
}
.ermaiButton {
  background: rgba(30, 82, 179, 0.2);
  transition: all 0.3s ease;
  width: auto;
  padding: 0 0.4rem;
  display: inline-block;
  text-align: center;
  height: 0.28rem;
  line-height: 0.28rem;
  margin-right: 0.08rem;
  font-size: 0.18rem;
  color: #b8ceff;
  font-family: MicrosoftYaHei;
  cursor: pointer;
}
.ermaiButton.active {
  background: rgba(30, 82, 179, 0.7);
  color: #ffffff;
}
.ant-picker {
  padding: 4px 11px !important;
}
.ant-picker-input > input {
  font-size: 14px!important;
  color: #a7c0fb !important;
}
.picker-center .ant-picker-input input {
  text-align: center;
}
.calendar {
  padding-left: 80px;
}
.ant-select-selector {
  line-height: 0.38rem !important;
  color: #a7c0fb !important;
  border: 1px solid #0076ff;
}
.ant-picker-separator {
  color: white!important;
}
.need-right-select .ant-select-arrow {
  width: 40px!important;
  font-size: 12px!important;
}
.ant-input {
  background-color: rgba(30, 82, 179, 0.2) !important;
  background-image: none;
  border: 1px solid #0076ff !important;
}
.ant-input-disabled {
  background-color: #112055 !important;
}
.ant-pagination {
  color: #a7c0fb !important;
}
.ant-pagination .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: transparent!important;
}
.ant-pagination-options-quick-jumper input {
  color: #a7c0fb !important;
}
.ant-pagination-item-ellipsis {
  color: #a7c0fb !important;
}
.ant-pagination-item a {
  color: #a7c0fb !important;
}
.ant-select-arrow {
  color: #a7c0fb !important;
}
.ant-pagination-item {
  border-color: transparent!important;
}
.ant-pagination-options-quick-jumper input {
  border-color: transparent!important;
}
.ant-descriptions-item-label {
  font-size: 0.18rem !important;
  font-family: MicrosoftYaHei;
  color: #b8ceff !important;
}
.ant-descriptions-item-content {
  font-size: 0.18rem !important;
  font-family: MicrosoftYaHei!important;
  color: #ffffff !important;
}
.ant-descriptions-row > th {
  padding-bottom: 0.08rem !important;
}
.ant-descriptions-row > td {
  padding-bottom: 0.12rem !important;
}
.ant-table-thead > tr > th {
  border-bottom: 1px solid rgba(0, 118, 255, 0.5) !important;
  font-size: 0.16rem !important;
  font-family: MicrosoftYaHei;
  color: #b8ceff !important;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid rgba(0, 118, 255, 0.5) !important;
  font-size: 0.16rem !important;
  font-family: MicrosoftYaHei;
  color: #b8ceff !important;
}
.new_header {
  border-left: 1px solid rgba(0, 118, 255, 0.5) !important;
  border-right: 1px solid rgba(0, 118, 255, 0.5) !important;
}
.new_header .ant-table-thead tr .ant-table-cell {
  border-top: 0.04rem solid #00d0ff;
  padding: 0.17rem 0.1rem;
}
.ant-checkbox-wrapper {
  font-size: 0.18rem !important;
  font-family: MicrosoftYaHei;
  color: #b8ceff !important;
}
.ant-checkbox + span {
  padding-right: 0.08rem !important;
  padding-left: 0.08rem !important;
}
.ant-checkbox-group-item {
  margin-right: 4px!important;
}
.ant-modal-content {
  background: #112055 !important;
}
.ant-modal-header {
  background: rgba(30, 82, 179, 0.2) !important;
  border-top: 4px solid #00d0ff;
  color: white;
  padding: 0.1rem 0.15rem !important;
  border-bottom: 1px solid rgba(0, 118, 255, 0.5) !important;
}
.ant-modal-title {
  font-size: 0.18rem !important;
  font-family: MicrosoftYaHei;
  color: #e9f0ff !important;
}
.ant-modal-confirm .ant-modal-confirm-title {
  color: white!important;
}
.ant-modal-confirm .ant-modal-confirm-content {
  color: white!important;
}
.ant-modal-footer {
  height: 0.6rem;
  padding: 0;
}
.ant-modal-footer .title_right {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 0.32rem;
}
.ant-modal-footer .title_right > div {
  margin-left: 0.1rem;
  font-size: 14px;
  color: #b8ceff;
  position: relative;
  display: flex;
  align-items: center;
}
.ant-modal-footer .title_right .sendMessageBtn {
  display: flex;
  flex-direction: row;
  height: 0.32rem;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 0.22rem;
}
.ant-modal-footer .title_right .sendMessageBtn .ermaiButton {
  margin-left: 0.08rem;
  margin-top: 0;
  height: 0.32rem;
  line-height: 0.32rem;
}
.ant-modal-footer .title_right .sendMessageBtn .col_line {
  position: relative;
}
.ant-modal-footer .title_right .sendMessageBtn .col_line::before {
  left: 0;
}
.ant-modal-footer .title_right .sendMessageBtn .col_line::after {
  left: 0;
}
.ant-modal-footer {
  border-top: 1px solid rgba(0, 118, 255, 0.8) !important;
  margin: 0 0.15rem;
  position: relative;
}
.ant-modal-footer::after {
  content: '';
  position: absolute;
  left: 0;
  top: -1px;
  height: 1px;
  width: 4px;
  background: #00d0ff;
  box-shadow: 0 2px 3px 0 #00d0ff;
}
.ant-modal-footer::before {
  content: '';
  position: absolute;
  right: 0;
  top: -1px;
  height: 1px;
  width: 4px;
  background: #00d0ff;
  box-shadow: 0 2px 3px 0 #00d0ff;
}
.ant-drawer-content {
  background: #112055 !important;
}
.ant-drawer-header {
  background: rgba(30, 82, 179, 0.2) !important;
  border-top: 4px solid #00d0ff;
  border-bottom: 1px solid rgba(0, 118, 255, 0.5) !important;
}
.ant-drawer-header .ant-drawer-title {
  color: white!important;
}
.ant-drawer-header .ant-drawer-close {
  color: white;
}
.ant-drawer-body {
  color: white;
}
.ant-form-item-label > label {
  color: #b8ceff !important;
}
.ant-form-item {
  margin-bottom: 0.1rem !important;
  color: white!important;
}
.ant-divider-inner-text {
  color: white;
}
.ant-upload {
  font-size: 18px;
  font-family: MicrosoftYaHei;
  color: rgba(184, 206, 255, 0.5) !important;
}
.ant-upload.ant-upload-select-picture-card {
  width: 172px!important;
  height: 172px!important;
  border: 1px solid #0076ff !important;
}
.ant-checkbox-inner {
  background-color: transparent!important;
}
.ant-select-dropdown {
  background-color: #142457 !important;
  color: #fff !important;
}
.ant-select-item-option-content {
  color: #fff;
}
.ant-select-item-option:hover {
  background: rgba(30, 82, 179, 0.6) !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #1E52B3 !important;
}
.ant-select-multiple .ant-select-selection-item {
  background: #1E52B3 !important;
  border: 0!important;
}
.ant-select-multiple .ant-select-selection-item .anticon-close {
  color: white!important;
}
.ant-radio-wrapper {
  font-size: 0.18rem !important;
  font-family: MicrosoftYaHei;
  color: #b8ceff !important;
}
.ant-radio-inner {
  background-color: transparent!important;
}
.ant-dropdown-menu {
  background-color: #142457 !important;
  color: #fff !important;
}
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  color: #fff !important;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  color: #fff !important;
}
.ant-dropdown-menu-item-active {
  background: rgba(30, 82, 179, 0.3) !important;
}
.ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-submenu-title > a:hover {
  background: rgba(30, 82, 179, 0.3) !important;
}
.ant-picker-panel-container {
  background: #142457 !important;
  color: #b8ceff !important;
}
.ant-picker-panel {
  border-bottom: 1px solid rgba(0, 118, 255, 0.8) !important;
}
.ant-picker-header {
  border-bottom: 1px solid rgba(0, 118, 255, 0.8) !important;
}
.ant-picker-header button {
  color: #b8ceff !important;
}
.ant-picker-content th {
  color: #b8ceff !important;
}
.ant-picker-cell {
  color: #b8ceff !important;
}
.ant-picker-cell-inner:hover {
  background: rgba(30, 82, 179, 0.3) !important;
  color: #b8ceff !important;
}
.ant-picker-header-view {
  color: #b8ceff !important;
}
.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid rgba(0, 118, 255, 0.8) !important;
}
.ant-picker-range-arrow::after {
  border-color: rgba(0, 118, 255, 0.8) rgba(0, 118, 255, 0.8) transparent transparent !important;
}
.ant-picker-ranges {
  text-align: center!important;
}
.ant-picker-ranges .ant-picker-ok {
  float: none!important;
  margin: 0 auto!important;
}
.ant-picker-time-panel-cell-inner {
  color: #b8ceff !important;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: rgba(30, 82, 179, 0.3) !important;
}
.ant-picker-time-panel-column .ant-picker-time-panel-cell-inner:hover {
  background: rgba(30, 82, 179, 0.3) !important;
}
.ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid rgba(0, 118, 255, 0.8) !important;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #b8ceff !important;
  margin-bottom: 3px;
}
.ant-table-title {
  color: white;
  border-bottom: 1px solid rgba(0, 118, 255, 0.5) !important;
  padding: 0.1rem 0.1rem;
  line-height: 0.24rem;
  border-top: 1px solid rgba(0, 118, 255, 0.5) !important;
}
